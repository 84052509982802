





























import { formatRupiah } from "@/apps/core/modules/utils/text";
import { computed, defineComponent } from "@vue/composition-api";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import router from "@/router";
import { Produk } from "../models/produk";
import { pengajuanState } from "@/apps/pinjaman/modules/stores/pengajuan";

export default defineComponent({
  name: "ProdukListBox",
  props: {
    produk: { type: Object, required: true },
  },
  setup(props) {
    const imgUrl = computed(() => {
      if (props.produk.label) {
        const label = props.produk.label.toLowerCase();
        return require(`@/apps/produk/assets/img/${label}.png`);
      }
      return require("@/apps/produk/assets/img/pisa.png");
    });

    const ajukan = (produk: Produk) => {
      if (produk.id !== null) {
        pengajuanState.reset();
        pengajuanState.produkName = produk.nama;
        router.push({ name: 'pinjaman-ajukan', params: { id: produk.id }});
      }
    }
    
    return {
      // Data
      detailUrl: "/produk",
      formatRupiah,

      // Computed
      imgUrl,

      // Method
      ajukan,
      toUserDateFormat,
    };
  },
});
